(function ($, window, document) { // jQuery wrapper
    // Page Functions
    window.PageFunctions = {
        // Smooth scroll on page anchor links.
        // Will scroll to the top for # links.

        currentPopup: {},

        smoothScroll: function () {
            $('a[href*="#"]').click(function () {

                var _this = $(this);

                if (!_this.hasClass('tribe_events_filters_close_filters') && !_this.hasClass('tribe_events_filters_show_filters')) {

                    var scrollTarget;
                    // Get current page url
                    var currentURL = window.location.href;
                    var currentURLarray = currentURL.split('#');
                    var currentURLfragment = currentURLarray[0];
                    // remove trailing slash on url fragment
                    if (currentURLfragment.charAt(currentURLfragment.length - 1) === "/") {
                        currentURLfragment = currentURLfragment.slice(0, -1);
                    }
                    // Get link url
                    var thisURL = $(this).attr('href');
                    var thisURLarray = thisURL.split('#');
                    var thisURLfragment = thisURLarray[0];
                    // remove trailing slash on url fragment
                    if (thisURLfragment.charAt(thisURLfragment.length - 1) === "/") {
                        thisURLfragment = thisURLfragment.slice(0, -1);
                    }
                    // If the link is a link to current page, smooth scroll
                    if (currentURLfragment === thisURLfragment || thisURLfragment.length < 1) {
                        // If the link is just # set scrollTarget to top of page
                        if ($(this).attr('href') === '#') {
                            scrollTarget = 0;
                        } else { // Otherwise locate the target element and find its offset from the top
                            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                                var target = $(this.hash);
                                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                                if (target.length) {
                                    scrollTarget = target.offset().top;
                                }
                            }
                        }
                        // Scroll the page to the target.
                        $('html, body').animate({
                            scrollTop: scrollTarget
                        }, 1000);
                        return false; // Exit.
                    }
                }

            });
        },

        toggleSearch: function (searchClass) {
            $(searchClass).on('click', '.j-search--submit', function (e) {
                var searchBlock = $(this).parents(searchClass);
                var search_value = searchBlock.find('.j-search--input').val();
                // If the search is closed, open it
                if (!searchBlock.hasClass('j-status__search-open')) {
                    e.preventDefault();//
                    searchBlock.addClass('j-status__search-open');
                    searchBlock.find('.j-search--input').focus();
                    return;
                }
                // If the search is too short, close it;
                if (search_value.length < 3) {
                    e.preventDefault();
                    searchBlock.find('.j-search--input').val('');
                    searchBlock.removeClass('j-status__search-open');
                    searchBlock.find('.j-search--submit').focus();
                    return;
                }

                // Otherwise, complete the search
                return;
            });
        },

        setupCarouselBlock: function () {
            setupCarouselBlock();
        },
        setupEventsCarousel: function () {
            setupEventsCarousel();
        },
        setupVideoPopup: function () {
            jQuery('.j-video').on('click', function () {
                var link = jQuery(this).attr('data-link').trim();
                var iframe = setupIframeVideoContent(link);
                jQuery.featherlight(iframe, {
                    variant: 'video',
                });
            });
        },
        setupPopupLinks: function () {
            jQuery('.j-popup--button').on('click', function () {

                var _this = $(this);
                var formID = _this.data('form');

                var formName = "#j-gf-popup--" + formID;

                var form = $(formName);


                var form = $(formName);

                $('body').addClass('j-state--popup__open');
                form.removeClass('s-hidden');
                $(window).trigger('resize');

            });
    
            
            $('.j-gf-popup--close').on('click', function () {
                $(this).parents('.j-gf-popup').addClass('s-hidden');
                $('body').removeClass('j-state--popup__open');
            });
        },
        setupInstagram: function () {
            setupInstagram();
        },
        loadMoreButton: function () {
            loadMoreButton();
        },
        ajaxPosts: function(){

            ajaxPosts();
        }

    };
    function ajaxPosts(){
        $(".loadmore-button").on('click', function (event) {
            var that = $(this);
            event.preventDefault();
            var post_type = that.attr('data-post-type');
            var page = parseInt(that.attr('data-page'));
            var per_page = parseInt(that.attr('data-per-page'));
            var max_page = parseInt(that.attr('data-max-page'));
            var offset = parseInt(that.attr('data-offset'));
            var tax = that.attr('data-tax');
            var term = that.attr('data-term');


            if (!that.hasClass('active')) {
                that.addClass('active');
                $('.o-recent-news--news-list').addClass('active');

                if (page >= max_page) {
                    that.addClass("s-hidden");
                }

            } else {

                page++;

                var dataArgs = {
                    action: 'gstar_ajax_pagination',
                    post_type: post_type,
                    page: page,
                    per_page: per_page,
                    offset: offset,
                    tax: tax,
                    term: term,
                }
                $.ajax({
                    url: pagedata.ajaxurl,
                    type: 'post',
                    data: dataArgs,
                    success: function (html) {
                        $(html).appendTo($('.o-recent-news--news-list'));
                        that.attr('data-page', page);
                        console.log(page);
                        console.log(max_page);
                        if (page >= max_page) {
                            that.addClass("s-hidden");
                        }
                    }
                });
            }
        });
    }
    function loadMoreButton(){
        $('.j-load-more--button').on('click',function(){
            var _this = $(this);
            var current_group = _this.attr('data-group');
            var total_groups = _this.attr('data-total');
            var next_group = parseInt(current_group) + 1;
            var container = _this.parents('.j-load-more--container');
            var group_class = '.j-load-more__' + current_group;
            container.find(group_class).slideDown('slow');
            if(next_group <= total_groups){
                _this.attr('data-group',next_group);
            }else{
                _this.addClass('s-hidden');
            }
        });
    }
    function setupEventsCarousel() {
        $('.j-events-carousel__half').slick({});
        $('.j-events-carousel__full').slick({
            slidesToShow: 2,
            dots: false,
            responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    slidesToScroll: 1,
                }
            },
        ]
        });
    }

    function setupCarouselBlock() {
        $('.j-carousel').slick({
            dots: false,
            arrows: false,
            autoplay: false,
            adaptiveHeight: true
        }).on('afterChange', function (event, slick, currentSlide) {
            var slide = '.j-navigation__' + currentSlide;
            $(slide).addClass('active').siblings().removeClass('active');
        });

        $('.j-carousel--navigation').on('click', function () {
            var _this = $(this);
            var slide = _this.data('slide');
            $('.j-carousel').slick('slickGoTo', slide);
        });
    }


    function setupInstagram() {
        var feedDataArray = $('.j-instagram');

        if (typeof feedDataArray != "undefined") {
            feedDataArray.each(function (index) {
                var feedData = $(feedDataArray[index]);
                if (!feedData.hasClass('activated')) {
                    feedData.addClass('activated');

                    var user_id = feedData.data('user_id');
                    var client_id = feedData.data('client_id');
                    var access_token = feedData.data('access_token');



                    if (typeof user_id != "undefined" &&
                        typeof client_id != "undefined" &&
                        typeof access_token != "undefined" &&
                        user_id != "" &&
                        client_id != "" &&
                        access_token != "" ) {


                        // JavaScript to be fired on the home page
                        var feed = new Instafeed({
                            limit: 9,
                            get: 'user',
                            userId: user_id,
                            clientId: client_id,
                            accessToken: access_token,
                            target: feedData.data('target'),
                            resolution: 'standard_resolution'
                        });
                        feed.run();
                    }
                }
            });
        }
    }


    function setupIframeVideoContent(html, playerID) {

        if (typeof playerID === 'undefined') {
            playerID = 'none';
        }

        var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
        var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
        var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;
        var replacement;

        if (pattern1.test(html)) {
            replacement = '<iframe width="420" height="345" src="//player.vimeo.com/video/$1?autoplay=1&enablejsapi=1&playerapiid=' + playerID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

            html = html.replace(pattern1, replacement);
        }


        if (pattern2.test(html)) {
            replacement = '<iframe width="420" height="345" src="http://www.youtube.com/embed/$1?autoplay=1&enablejsapi=1&playerapiid=' + playerID + '" frameborder="0" allowfullscreen></iframe>';
            html = html.replace(pattern2, replacement);
        }


        if (pattern3.test(html)) {
            replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
            html = html.replace(pattern3, replacement);
        }


        html = '<div class="entry-content-asset">' + html + '</div>';


        return html;


    }

}(window.jQuery, window, document)); // End of jQuery wrapper

