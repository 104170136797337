/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                window.PageFunctions.smoothScroll();
                window.MenuFunctions.setupMenuToggle();
                window.MenuFunctions.setupSubMenuToggle();
                window.PageFunctions.toggleSearch('.o-header--search');
                window.PageFunctions.toggleSearch('.o-search--search');

                window.PageFunctions.setupVideoPopup();
                window.PageFunctions.setupPopupLinks();
                window.MenuFunctions.setupStickyMenu();

                // JavaScript to be fired on all pages, after page specific JS is fired
                jQuery(document).bind('gform_page_loaded', function(event, form_id, current_page){
                   jQuery('.featherlight-content').animate({ scrollTop: (0) });
                }); // code to be trigger when next/previous page is loaded

            },
            finalize: function () {

            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Home page
        'page_block_contact': {
            init: function () {
                // JavaScript to be fired on the home page
                window.mapDisplay.setup($('.j-map'));
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Home page
        'page_block_faculty': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.PageFunctions.loadMoreButton();
                $('.j-faculty--trigger').featherlightGallery();
            }
        },
        // Home page
        'page_block_carousel': {
            init: function () {
                window.PageFunctions.setupCarouselBlock();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Home page
        'page_block_recent_news': {
            init: function () {

                window.PageFunctions.ajaxPosts();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.PageFunctions.setupEventsCarousel();//
                window.PageFunctions.setupInstagram();
            }
        },


        // Home page
        'page_block_split_content': {
            init: function () {

            },
            finalize: function () {


                // JavaScript to be fired on the home page, after the init JS

                window.PageFunctions.setupInstagram();
                window.PageFunctions.setupEventsCarousel();//

            }
        },
        'page_block_gallery': {
            init: function () {

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.PageFunctions.loadMoreButton();

            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

