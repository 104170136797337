(function ($, window, document) { // jQuery wrapper

    // Menu Functions
    window.MenuFunctions = {

        header: $(".o-header"),
        menu: $(".o-header--navigation"),

        // Toggle Main menu when menu toggle is clicked.
        setupMenuToggle: function () {
            this.header.on('click', '.j-menu--toggle', function () {
                $('body').addClass('j-state--popup__open');
                $.featherlight($('.j-mobile-navigation'), {
                    variant: 'main-menu',
                });
            });

            $('.featherlight-close-icon').on('click', function () {
                $('body').removeClass('j-state--popup__open');
            });
        },
        // Creates sub menu toggle fields, and controls their function
        setupSubMenuToggle: function () {
            var mobileMenu = $('.j-mobile-navigation');
            mobileMenu.find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
            mobileMenu.find('.menu-slide').on('click', function (e) {
                $(this).toggleClass('open');
                e.preventDefault();
                e.stopPropagation();
                $(this).siblings('.sub-menu').stop(true, true).slideToggle(500);
            });
        },

        setupStickyMenu: function () {
            $(window).on('scroll', function () {
                if ($(document).scrollTop() > $('.o-banner').height()) {
                    $(".o-header__stuck").addClass('active');
                } else {
                    $(".o-header__stuck").removeClass('active');
                }
            });
        },
    };

}(window.jQuery, window, document)); // End of jQuery wrapper

